/* eslint-disable vars-on-top */
/* eslint-disable no-new-func */
/* eslint-disable no-new */
/* eslint-disable no-var */

// Check if browser is modern enough to run the app
function isModernBrowser() {
    try {
        // Check Promise.allSettled
        if (typeof Promise.allSettled !== 'function') {
            return false;
        }

        // Check Optional chaining operator and Nullish coalescing operator
        new Function('obj', 'return obj?.foo ?? "default";');

        // Check async/await
        new Function('async function foo() { await Promise.resolve(); }')();

        // Check ES Modules and dynamic import
        new Function('return import("").catch(() => {});');
    } catch (e) {
        return false;
    }
    return true;
}

var localizations = {
    en: {
        title: 'Outdated Browser Warning',
        content:
            'This browser is no longer supported. For your security, please upgrade to one of the following browsers: ' +
            '<a href=\'https://www.google.com/chrome\' target="_blank">Google Chrome</a>, ' +
            '<a href=\'https://www.mozilla.org/firefox\' target="_blank">Mozilla Firefox</a>, ' +
            '<a href=\'https://www.microsoft.com/edge\' target="_blank">Microsoft Edge</a> ' +
            'or <a href=\'https://www.apple.com/safari\' target="_blank">Apple Safari</a>.',
    },
    es: {
        title: 'Advertencia de navegador desactualizado',
        content:
            'Este navegador ya no es compatible. Por su seguridad, actualice a uno de los siguientes navegadores: ' +
            '<a href=\'https://www.google.com/intl/es/chrome/\' target="_blank">Google Chrome</a>, ' +
            '<a href=\'https://www.mozilla.org/es-ES/firefox/new/\' target="_blank">Mozilla Firefox</a>, ' +
            '<a href=\'https://www.microsoft.com/es-es/edge\' target="_blank">Microsoft Edge</a>, ' +
            '<a href=\'https://www.apple.com/es/safari/\' target="_blank">Apple Safari</a>.',
    },
};

(function () {
    var container = document.getElementById('preload-container');
    // the container will be null if the app is loaded successfully
    if (!container) {
        return;
    }

    if (!isModernBrowser()) {
        var language = navigator.language.split('-')[0];
        var localization = localizations[language] || localizations.en;
        // Show error message
        var errorMessage = document.createElement('div');
        errorMessage.style.position = 'fixed';
        errorMessage.style.zIndex = 10;
        errorMessage.style.bottom = 0;
        errorMessage.style.left = 0;
        errorMessage.style.right = 0;
        errorMessage.style.top = 0;
        errorMessage.style.padding = '16px 32px 32px';
        errorMessage.style.textAlign = 'center';
        errorMessage.style.color = '#aa2e23';
        errorMessage.style.backgroundColor = '#fbf1e2';

        var errorMessageTitle = document.createElement('h1');
        errorMessageTitle.innerHTML = localization.title;
        errorMessageTitle.style.fontSize = '24px';
        errorMessageTitle.style.fontWeight = 500;
        errorMessageTitle.style.margin = '16px 0';

        var errorMessageContent = document.createElement('p');
        errorMessageContent.innerHTML = localization.content;

        errorMessage.appendChild(errorMessageTitle);
        errorMessage.appendChild(errorMessageContent);

        container.innerHTML = errorMessage.outerHTML;
    }
})();
